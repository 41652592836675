import { Modal, ModalActionType } from "./types";
import { ModalAction } from "./constants";

const initialState: Modal[] = [];

function modalsReducer(state = initialState, action: ModalActionType) {
  switch (action.type) {
    case ModalAction.DESTROY:
      return state.filter((modal) => modal.id !== action.id);
    case ModalAction.HIDE:
      return state.map((modal) => {
        if (modal.id !== action.id) {
          return modal;
        }
        return {
          ...modal,
          open: false,
        };
      });
    case ModalAction.INSERT:
      return [...state, { ...action.modal }];
    default:
      return state;
  }
}

export default modalsReducer;
