export enum ModalAction {
  DESTROY = "MODAL_DESTROY",
  INSERT = "MODEL_INSERT",
  HIDE = "MODAL_HIDE",
}

export enum ModalType {
  CONFIRM = "Confirm",
  FORM = "form",
  INFO = "info",
  LOADING = "loading",
}
