import "modules/theme/sass/_index.scss";
import { cookieConfig, gtmProduction, gtmUat, gtmG4Production } from "modules/external-scripts/body-components";
import { loadScript, inlineScript } from "modules/external-scripts";
import { isMobile } from "react-device-detect";

// Redux Wrapper
export { default as wrapRootElement } from "./src/modules/redux/ReduxWrapper";

const cookie = () => {
  loadScript("https://cc.cdn.civiccomputing.com/9/cookieControl-9.6.1.min.js").then(() => {
    inlineScript(cookieConfig);
  });
};

export const onClientEntry = () => {
  // Production
  if (process.env.GATSBY_AXELOS_ENV === "production") {
    window.onload = () => {
      var loaded = false;
      document.addEventListener("keydown", () => {
        if (!loaded) {
          inlineScript(gtmProduction);
          cookie();
          loaded = true;
        }
      });
      !isMobile
        ? document.addEventListener("mousemove", () => {
            if (!loaded) {
              inlineScript(gtmProduction);
              cookie();
              loaded = true;
            }
          })
        : document.addEventListener("touchmove", () => {
            if (!loaded) {
              inlineScript(gtmProduction);
              cookie();
              loaded = true;
            }
          });
    };
  }

    // PPL NEW Production
    if (process.env.GATSBY_AXELOS_ENV === "production") {
      window.onload = () => {
        var loaded = false;
        document.addEventListener("keydown", () => {
          if (!loaded) {
            inlineScript(gtmPPLProduction);
            cookie();
            loaded = true;
          }
        });
        !isMobile
          ? document.addEventListener("mousemove", () => {
              if (!loaded) {
                inlineScript(gtmPPLProduction);
                cookie();
                loaded = true;
              }
            })
          : document.addEventListener("touchmove", () => {
              if (!loaded) {
                inlineScript(gtmPPLProduction);
                cookie();
                loaded = true;
              }
            });
      };
    }

  //Prod G4 tags
  if (process.env.GATSBY_AXELOS_ENV === "production") {
    window.onload = () => {
      var loaded = false;
      document.addEventListener("keydown", () => {
        if (!loaded) {
          inlineScript(gtmG4Production);
          cookie();
          loaded = true;
        }
      });
      !isMobile
        ? document.addEventListener("mousemove", () => {
            if (!loaded) {
              inlineScript(gtmG4Production);
              cookie();
              loaded = true;
            }
          })
        : document.addEventListener("touchmove", () => {
            if (!loaded) {
              inlineScript(gtmG4Production);
              cookie();
              loaded = true;
            }
          });
    };
  }
  // UAT or Sandbox
  if (process.env.GATSBY_AXELOS_ENV === "uat" || process.env.GATSBY_AXELOS_ENV === "sandbox") {
    window.onload = () => {
      var loaded = false;
      document.addEventListener("keydown", () => {
        if (!loaded) {
          inlineScript(gtmUat);
          cookie();
          loaded = true;
        }
      });
      !isMobile
        ? document.addEventListener("mousemove", () => {
            if (!loaded) {
              inlineScript(gtmUat);
              cookie();
              loaded = true;
            }
          })
        : document.addEventListener("touchmove", () => {
            if (!loaded) {
              inlineScript(gtmUat);
              cookie();
              loaded = true;
            }
          });
    };
  }
};
