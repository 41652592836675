import React from "react";

// Post Body (After Page Load)
export const gtmProduction = `
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KXPMXMK');`;

export const gtmPPLProduction = `
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NJL9798');`;

export const gtmG4Production =`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-EGHMEFN7TW');`;
export const ProductionG4PreBodyComponents = [
  <noscript
    key="productionG4-noscript"
    dangerouslySetInnerHTML={{
      __html:        
      '<iframe src="https://www.googletagmanager.com/gtag/js?id=G-EGHMEFN7TW" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
    }}
  />,
];

export const ProductionPreBodyComponents = [
  <noscript
    key="production-noscript"
    dangerouslySetInnerHTML={{
      __html:
        '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KXPMXMK" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
    }}
  />,
];

export const ProductionPPLPreBodyComponents = [
   <noscript
    key="production-PPL-noscript"
    dangerouslySetInnerHTML={{
      __html:
        '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NJL9798" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
      }}
  />,
];


export const gtmUat = `
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KB8FGCX');`;

export const UatPreBodyComponents = [
  <noscript
    key="uat-noscript"
    dangerouslySetInnerHTML={{
      __html:
        '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KB8FGCX" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
    }}
  />,
];

export const cookieConfig = `
var config = {
  apiKey: "74c6b3e582c7e8a602cb3bff407629e656c4e6f4",
  product: "PRO_MULTISITE",
  initialState: "OPEN",
  mode: "GDPR",
  text: {
    accept: "Accept All",
    reject: "Reject All",
    acceptSettings: "Accept All",
    rejectSettings: "Reject All",
  },
  necessaryCookies: ["MMRefreshToken", "SIGN_UP_FOR_NEWSLETTER", "_GRECAPTCHA", "clientid"],
  setInnerHTML: true,
  branding: {
    removeAbout: true,
    backgroundColor: "#ffffff",
    fontColor: "#000000",
    fontSizeTitle: "22px",
    fontSizeIntro: "30px",
    fontSizeHeaders: "16px",
    fontSize: "14px",
    toggleColor: "#c4c5ca",
    toggleBackground: "#ffffff",
  },
  optionalCookies: [
    {
      name: "analytics",
      label: "Google Analytics",
      description:
        "Analytical cookies help us to improve our website by collecting and reporting information on its usage.",
      cookies: ["_ga", "_gid", "_gat", "__utma", "__utmt", "__utmb", "__utmc", "__utmz", "__utmv"],
      onAccept: function () {
        gtag("consent", "update", {
          analytics_storage: "granted",
        });
      },
      onRevoke: function () {
        gtag("consent", "update", {
          analytics_storage: "denied",
        });
      },
    },
  ],
  position: "RIGHT",
  theme: "LIGHT",
};
CookieControl.load(config);`;
