export const loadScript = (scriptUrl: string) => {
  const script = document.createElement("script");
  script.src = scriptUrl;
  script.async = false;
  script.setAttribute("integrity", "sha384-aqvfY6jJZz7P1H3oZsg8o0LwTPPzfe8oAUErRJ960teK4rPG++iAX5l5gyu0iEzf");
  script.setAttribute("crossorigin", "anonymous");
  document.body.appendChild(script);

  return new Promise<void>((res, rej) => {
    script.onload = function () {
      res();
    };
    script.onerror = function () {
      rej();
    };
  });
};

export const inlineScript = (content: string) => {
  const script = document.createElement("script");
  script.textContent = content;
  script.async = false;
  document.body.appendChild(script);
};
